<template>
	<div>
		<div class="position-relative">
			<img :src="imgPortada" class="img-background w-100" style="height: 300px;">
			<div class="position-absolute portada object-position-top text-white">
				<div class="h-100 d-flex align-items-center justify-content-center">
					<h1 class="playfairDisplay" v-text="title"></h1>
				</div>
			</div>
		</div>
		<div>
			<div class="form-row justify-content-center py-5">
				<div class="col-lg-8">
					<p class="text-center fs-5 text-muted mt-4 playfairDisplay">
						Hoy en día, además de artículos, <strong>los organizadores del evento también pueden crear una mesa de regalos creando sus propias experiencias y preferencias.</strong> Dependiendo que tipo de evento se llegara a realizar otros tipos de regalos como boletos para el teatro, entradas al cine, un día de spa o algún tour o viaje que los organizadores seleccionaron como regalo.
					</p>
				</div>
			</div>

			<div style="background-image: url(fondoImg);background-color:grey; background-position: center; background-repeat: no-repeat; background-size: cover;">
				<div class="container py-5">
					<div class="row">
						<div class="col-lg-6 mb-5 text-center text-white">
							<img src="https://gretnagreen.qodeinteractive.com/wp-content/uploads/2018/05/h4-custom-icon-img-1.png" class="img-fluid mb-3">
							<div class="d-flex align-items-center">
								<div class="text-center px-3">
									<h5>LA MEJOR INSPIRACIÓN Y LAS MEJORES MARCAS</h5>
									<p>
										Todo un mundo de posibilidades para crear, compartir y diseñar tu mundo. Echa a volar tu imaginación y diseña un estilo de vida que marque esos momentos que jamás olvidarás. Con la mesa de regalos OurGift recibes solo los regalos que tú quieras, siempre envueltos en una experiencia como ninguna.
									</p>
								</div>
							</div>
						</div>
						<div class="col-lg-6 text-center text-white">
							<img src="https://gretnagreen.qodeinteractive.com/wp-content/uploads/2018/05/h4-custom-icon-img-3.png" class="img-fluid mb-3">
							<div class="d-flex align-items-center">
								<div class="text-center px-3">
									<h5>TODO LO QUE SIEMPRE SOÑASTE</h5>
									<p>
										A un click de distancia.  Superar tus propias expectativas jamás habían sido tan sencillo y divertido.  Encuentra los mejores productos para cada oacacion especial, para poder elegir dentro del catálogo más exclusivo de productos.  Recibe lo que tu deseas y disfruta de una nueva forma de dar y recibir regalos.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bg-rose mb-3" v-if="false">
				<div class="container py-5">
					<div class="row justify-content-center align-items-center">
						<div class="col-lg-6 mb-3 text-center">
							<h3 class="playfairDisplay fs-2 fw-bold"></h3>
							<p class="mt-auto"></p>
						</div>
						<div class="col-lg-6 mb-3 text-center">
							<h3 class="playfairDisplay fs-2 fw-bold"></h3>
							<p class="mt-auto"></p>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bg-rose py-5">
				<div v-if="loadingProductosTipos" :style="{cursor:cursorCarousel}">
					<carousel :items-to-show="3" :wrapAround="true" v-if="productosTipos.length>0">
						<slide v-for="(productoTipo, index) in productosTipos" :key="index" class="p-3">
							<router-link :to="{name: 'productos.index', query: {tipo: productoTipo.CODIGO_PRODUCTO_TIPO}}" :title="productoTipo.DESCRIPCION_TIPO">
								<div class="h-100 d-flex flex-column">
									<div class="mb-2 mt-auto">
										<div class="w-75 mx-auto">
											<span v-if="productoTipo.adjuntos && productoTipo.adjuntos.length>0">
												<img :src="productoTipo.adjuntos[0].ARCHIVO" class="img-fluid">
											</span>
											<img :src="emptyIMG" class="img-fluid" v-else>
										</div>
									</div>
									<div class="mt-auto text-center">
										{{productoTipo.DESCRIPCION_TIPO}}
									</div>
								</div>
							</router-link>
						</slide>
					</carousel>
				</div>
				<div class="text-center" v-else>
					<div class="spinner-grow text-primary" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import imgPortada from '@/assets/img/backgrounds/portada.jpg'
	import emptyIMG from '@/assets/img/empty-image.png'
	import cusorImg from '@/assets/img/drag-cursor.png'
	import fondoImg from '@/assets/img/backgrounds/banner1.jpg'
	import 'vue3-carousel/dist/carousel.css'
	import { Carousel, Slide } from 'vue3-carousel'

	export default {
		components: {
			Carousel,
			Slide
		},
		data(){
			return {
				imgPortada,
				cusorImg,
				emptyIMG,
				fondoImg,
				cursorCarousel: `url('${cusorImg}'), auto`,
				loadingProductosTipos: false,
				productosTipos: []
			}
		},
		mounted(){
			this.getProductosTipos()
		},
		methods: {
			getProductosTipos(){
				this.loadingProductosTipos = true
				this.$store.dispatch('getProductosTipos', {
					pagination: 0
				}).then(res => {
					this.productosTipos = res.data || []
					return res
				}).catch(err => {
					return err
				}).catch(() => {
					this.loadingProductosTipos = false
				})
			},
		},
		computed: {
			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},
		}
	}
</script>